@import "Theme.scss";

.sidebar-content {
    width: calc(100% - 50px);
    margin-left: auto;
    padding: 15px;
    height: 100%;
    word-break: break-word;
}

.sidebar {
    position: fixed;
    top: 0;
    width: 40px;
    height: 100%;
    background-color: rgba(216,139,71, .95); //$SUMMER-3-hex;
    transition: width 0.3s ease;
    border-right: 1px $SUMMER-5-hex solid;
    padding-top: 15px;
    z-index:1000;
}

.sidebar .sidebar-position {
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 10px;
    text-align: left;
}

.sidebar .sidebar-position:hover {
    background-color: $SUMMER-2-hex;
    border-right: 2px $SUMMER-4-hex solid;
}

.sidebar svg {
    color: whitesmoke;
    min-width: 20px;
}

.sidebar span {
    width: 0;
    height: 0;
    //padding-buttom: 10px;
    padding: 10px 12px;
    transition: width 0.3s ease;
    color:white;
    //text-transform: uppercase;
    //text-shadow: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //background-color: red; //$SUMMER-4-hex
    //font-weight: bold;
}

.sidebar--open {
    width: 250px;
    padding-left: 10px;
    transition: width 0.3s ease;
}

.sidebar--open .sidebar-position span {
    display: block;
    width: 100%;
    height: auto;
}

.trigger {
    height: 50px;
    font-size: 19px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px 12px;
    background-color: $SUMMER-5-hex;
}

.visible{
    visibility: visible;
}

.hidden{
    visibility: hidden;
}
