/* Color Theme Swatches in Hex */
$SUMMER-1-hex: #085A8C;
$SUMMER-2-hex: #2976a6;
$SUMMER-3-hex: #D98B48;
$SUMMER-4-hex: #D9AE89;
$SUMMER-5-hex: #BF6545;

/* Color Theme Swatches in RGBA */
$SUMMER-1-rgba: rgba(8,89,140, 1);
$SUMMER-2-rgba: rgba(41,118,165, 1);
$SUMMER-3-rgba: rgba(216,139,71, 1);
$SUMMER-4-rgba: rgba(216,173,136, 1);
$SUMMER-5-rgba: rgba(191,101,68, 1);

/* Color Theme Swatches in HSLA */
$SUMMER-1-hsla: hsla(202, 88, 29, 1);
$SUMMER-2-hsla: hsla(202, 60, 40, 1);
$SUMMER-3-hsla: hsla(28, 65, 56, 1);
$SUMMER-4-hsla: hsla(28, 51, 69, 1);
$SUMMER-5-hsla: hsla(16, 48, 51, 1);

/* Color Theme Swatches in Hex */
$Glamour-Russia-1-hex: #013440;
$Glamour-Russia-2-hex: #734002;
$Glamour-Russia-3-hex: #A6691F;
$Glamour-Russia-4-hex: #401801;
$Glamour-Russia-5-hex: #BF8C6F;

/* Color Theme Swatches in RGBA */
$Glamour-Russia-1-rgba: rgba(1,52,63, 1);
$Glamour-Russia-2-rgba: rgba(114,64,2, 1);
$Glamour-Russia-3-rgba: rgba(165,105,31, 1);
$Glamour-Russia-4-rgba: rgba(63,24,1, 1);
$Glamour-Russia-5-rgba: rgba(191,140,110, 1);

/* Color Theme Swatches in HSLA */
$Glamour-Russia-1-hsla: hsla(191, 96, 12, 1);
$Glamour-Russia-2-hsla: hsla(33, 96, 22, 1);
$Glamour-Russia-3-hsla: hsla(33, 68, 38, 1);
$Glamour-Russia-4-hsla: hsla(22, 96, 12, 1);
$Glamour-Russia-5-hsla: hsla(22, 38, 59, 1);

/* Color Theme Swatches in Hex */
$Green-to-Red-0-hex: #04c52a;
$Green-to-Red-1-hex: #62ee45;
$Green-to-Red-2-hex: #eed809;
$Green-to-Red-3-hex: #f98106;
$Green-to-Red-4-hex: #de410a;
$Green-to-Red-5-hex: #be180a;
