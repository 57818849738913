@import "Theme.scss";

.main {
  margin-left: 40px;
  //margin-right: 20px;
  //padding-left: 20px;
  padding: 1em;
}

header {
  height: 100px;
  //z-index: 2;
  display: block;
}

footer {
  padding: 10px;
  //display: flex;
  //align-items: center;
  //display: block;
  margin-top: 40px;
  margin-left: 40px;
  color: ghostwhite;
  height: 100px;
  //border: #735D58 solid 5px;
  background-color: #405559;
  text-align: center;
  //vertical-align: middle;
  font-size: small;
  justify-content: center;
}

.filter {
  margin-right: auto;
  margin-left: auto;
  background-color: #f2f1dc;
  //height: 50px;
  padding: 15px;
  border-radius: 4px;
}

