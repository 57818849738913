@import "Theme.scss";

* {
  box-sizing: border-box;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.form-inline label {
  margin: 5px 10px 5px 0;
}

.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.form-inline select {
  font-size: medium;
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  min-width: 250px;
}

.form-inline button {
  padding: 10px 20px;
  background-color: dodgerblue;
  border: 1px solid #ddd;
  color: white;
  cursor: pointer;
}

.form-inline button:hover {
  background-color: royalblue;
}

@media (max-width: 800px) {
  .form-inline input {
    //margin: 10px 0;

  }

  .form-inline select {
    width: 100%;
  }

  .form-inline {
    flex-direction: column;
    align-items: stretch;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 400px;
  max-height: 250px;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  border: 1px solid white;
  border-radius: 4px;
  z-index: 3;
  margin: 0;
  padding: 0;
  text-align: left;
}

.dropdown-content ul {
  padding: 0;
  margin: 0;
}

.dropdown-content li {
  color: $Glamour-Russia-1-hex;
  padding: 12px 16px;
  display: block;
}

.dropdown-active {
  background-color: #ddd;
}
