@import "Layout.scss";
@import "Theme.scss";
@import "Modal.css";
@import "Sidebar.scss";
@import "Table.scss";
@import "Form.scss";


body {
  font-family: Roboto, sans-serif;
}

h1 {
  font-size: 36px;
  font-weight: bold;
}

.pagination {
  margin-left: auto;
  margin-right: auto;
}

.pageSize {
  background-color: whitesmoke;
  position: absolute;
  /*padding-bottom: 10px;*/
  right: 0;
  bottom: 0;
  /*width: 150px;*/
}

.center {
  text-align: center;
}

.pagination button {
  border: 0;
}

.title {
  text-align: left;
}

.sort-icon {
  /*display: block*/
}

.subtitle {
  font-size: 18px;
  margin-top: -20px;
  color: rgba(1, 52, 64, .9);
  //font-weight: normal;
}

@media (max-width: 800px) {
  h1 {
    font-size: 28px;
  }
  .subtitle {
    font-size: 14px;
  }
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
}

.red {
  color: red;
}

button.link {
  background: none;
  border: none;
  cursor: pointer;
  color: wheat;
  text-decoration: brown;
  font-size: 13px;
  font-weight: bold;
}

button.link:hover {
  color: yellow;
}

.link {
  color: $SUMMER-1-hex;
  //font-family: helvetica, sans-serif;
  text-decoration: none;
  //letter-spacing: .01em;
  transition: text-shadow .3s;
  //text-transform: uppercase;
}

.link:hover {
  color: black;
  text-shadow: 0 0 .65px #333, 0 0 .65px #333;
  //font-weight: bolder;
}

.link:active {
  color: #EE0000;
}

.link:visited {
  color: #551A8B;
}


.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.section {
  display: block;
}

.section-title {
  color: $Glamour-Russia-2-hex;
}

.small {
  font-size: 10px;
  color: $SUMMER-2-hex;
}

.link:hover {
  cursor: pointer;
  position: relative;
}

.colorCodes {
  float: right;
}

.box {
  color: $Green-to-Red-0-hex;
  width: 20px;
  height: 20px;
  margin: 5px;
  border: 0 solid rgba(0, 0, 0, 1);
  display: inline-block;
  //cursor: auto;
}

.green-to-red-0 {
  background-color: $Green-to-Red-0-hex;
}

.green-to-red-1 {
  background-color: $Green-to-Red-1-hex;
}

.green-to-red-2 {
  background-color: $Green-to-Red-2-hex;
}

.green-to-red-3 {
  background-color: $Green-to-Red-3-hex;
}

.green-to-red-4 {
  background-color: $Green-to-Red-4-hex;
}

.green-to-red-5 {
  background-color: $Green-to-Red-5-hex;
}

.grey {
  background-color: grey;
}

.show {
  display: block;
}
